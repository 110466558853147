<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogSendVisible"
    width="25%"
    :before-close="handleSendClose"
  >
    <el-timeline>
      <el-form
        :model="ruleForm"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col>
            <el-form-item
              label="银行卡号"
              prop="bankCardNumber"
              :rules="[
                { required: true, message: '请输入银行卡号,前缀加#', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.number="ruleForm.bankCardNumber"
                clearable
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-timeline>
  </el-dialog>
</template>
<script>
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      span: 12,
      title: "新增",
      dialogSendVisible: true,
      rolesData: "",
      disabled: false,
      ruleForm: {
        bankCardNumber:'#',
        isDelete:0,
      },
    };
  },
  methods: {
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const user_info = localStorage.getItem("user_info");
          const user = JSON.parse(user_info);
          this.ruleForm.createBy = user.nickName || user.account;
          let url = this.$api.addBankCardWhiteListItems;
          if (this.row && this.row.id) {
            url = this.$api.adminEdit;
          }
          this.ruleForm.bankCardNumber = this.ruleForm.bankCardNumber.replace("#","");
          console.log(this.ruleForm.bankCardNumber);
          this.post(url, this.ruleForm);
          console.log(this.ruleForm,"success submit!!");
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$parent.listData();
          this.$parent.dialogEditVisible = false;
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    if (this.row && this.row.id) {
      this.title = "编辑";
      this.ruleForm = this.row;
      this.disabled = true;
    }
  },
};
</script>
