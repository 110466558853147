<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>系统</el-breadcrumb-item>
          <el-breadcrumb-item>白名单</el-breadcrumb-item>
          <el-breadcrumb-item>银行卡三要素</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="状态">
              <el-select v-model="where.isDelete" placeholder="请选择" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="未删除" value="0"></el-option>
                <el-option label="已删除" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="银行卡号">
              <el-input
                      v-model="where.bankCardNumber"
                      placeholder="银行卡号"
                      clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="创建人">
              <el-input
                      v-model="where.createBy"
                      placeholder="创建人"
                      clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button
                type="primary"
                @click="showDailog({})"
                icon="el-icon-plus">新增</el-button>
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="exportData"
                disabled
                icon="el-icon-tickets">导出数据</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="bankCardNumber" label="银行卡号"></el-table-column>
          <el-table-column prop="createBy" label="创建人"></el-table-column>
          <el-table-column prop="isDelete" label="状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isDelete === 0">未删除</el-tag>
              <el-tag type="warning" v-else-if="scope.row.isDelete === 1">已删除</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="日期"></el-table-column>
          <el-table-column label="操作" fixed="right" width="180px">
            <template slot-scope="scope">
              <el-button
                type="danger"
                class="el-icon-delete"
                @click="deleteBtn(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
      <add :row="row" @dialog="getDialog" v-if="dialogEditVisible"></add>
    </div>
  </div>
</template>

<script>
import add from "@/components/admin/white_list/three_elements/add.vue";
export default {
  components: {
    add,
  },
  props: {
    state: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      row: {},
      loading: false,
      dialogDetailVisible: false,
      dialogEditVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      rolesData: [],
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        bankCardNumber:'',
        createBy:'',
        isDelete:'',
      },
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.$post(this.$api.getBankCardWhitelistList, this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    exportData() {
      // console.log("exportData");
    },
    getDialog(val) {
      this.dialogDetailVisible = val;
      this.dialogEditVisible = val;
    },
    showDailog(data) {
      this.row = data;
      this.dialogEditVisible = true;
    },
    deleteBtn(id) {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        const url = this.$api.delBankCardWhitelist+id;
        this.post(url, this.form);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    post(url, data) {
      let that = this;
      this.$get(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          that.listData();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
  },
  created() {
    this.listData();
  },
};
</script>
<style lang="scss" scoped>
.endDate {
  color: #c0c4cc;
}
.el-image {
  height: 100px;
  width: 100px;
}
.el-select {
  width: 120px;
}
</style>
